import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { toggleField } from '../reducers/captive'

import Header from '../components/header'
import Intro from '../components/sections/intro'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'
import Existing from '../components/apply/existing'

import KidsBeachLines from '../images/kids-beach-circle-lines.png'

const AltEPage = ({ location, toggleField, captive }) => {
  useEffect(() => {
    toggleField('altHeaderLayout', true)
  }, [captive.altHeaderLayout])

  return (
    <Layout phone="877-755-6327" location={location}>
      <SEO
        title="Life Insurance Offers"
        keywords={[`life`, `insurance`, `plans`, `affordable`]}
      />
      <Header phone="877-755-6327" grayLogo className="large" />
      <Intro
        showTitle
        showInfoBox={false}
        title="Get the best life insurance quotes, for free."
        subTitle="Getting a quote through Life Insurance Companion is also totally safe and secure. Just answer a few questions for us."
        classNames="rightBg"
        bgImage={KidsBeachLines}
      >
        <div className="form">
          <Existing landing={true} title="Are you currently insured?" />
        </div>
      </Intro>
      <main id="main">
        <Partners />
        <HomeContent />
      </main>
    </Layout>
  )
}

export default connect((state) => state, { toggleField })(AltEPage)
